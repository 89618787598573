html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

#footer { 
  margin-top: auto 
}

a:hover {
  color: #22c4a1!important;
}
