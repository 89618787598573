body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
  border-color: #22C5A1;
}

.ui.selection.active.dropdown {
  border-color: #22C5A1!important;
}

.ui.green.button, .ui.green.buttons .button {
  background-color: #22c5a29f;
}

.ui.green.button:hover, .ui.green.buttons:hover .button:hover {
  background-color: #22C5A1;
}

.ui.form textarea:focus {
  border-color: #22C5A1;
}

.ui.inverted.green.button, .ui.inverted.green.buttons .button {
  box-shadow: 0 0 0 2px #22C5A1 inset!important;
  color: #22C5A1;
}

.ui.inverted.green.button:hover, .ui.inverted.green.buttons:hover .button:hover {
  background-color: #22C5A1;
}

.menu-item-active {
  font-weight: bold;
  color: #22C5A1 !important;
}